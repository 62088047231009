import api from "../../../../../../apiSingleton";
import React, { useEffect, useState, useMemo, useCallback } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { useComboBox } from "hooks/useComboBox";

import {
  increaseOrderItem,
  decreaseOrderItem,
  updateOrderItemComment,
  editCuantityOrder,
  selectSwappableProduct,
  selectPizzaHalf,
} from "store/actions/order";

import { IconButton } from "components/ui-kit/buttons/IconButton";
import { TextArea } from "components/ui-kit/inputs/TextArea";
import { Dropdown } from "components/ui-kit/inputs/Dropdown";
import Select from "components/ui-kit/Select";
import { TextInput } from "components/ui-kit/inputs/TextInput";

import { getAdditionPrice } from "utils/helpers/price";
import { countDops } from "utils/helpers/payment";
import { isSwappableProduct } from "utils/helpers/orders";
import { pizzaHalvesArticles } from "constants/halves";

import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import DeleteIcon from "@mui/icons-material/Delete";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

// Additions
import RenderAdditions from "./Additions";
import RenderComboBoxSlots from "./ComboBoxes";

// Styled components
import { Item, Title } from "../styles";

const RenderItem = ({
  index,
  id,
  item,
  root,
  order,
  price,
  city,
  restaurant,
  deliveryType,
  pricePeint,
  onOpenDeleteModal,
  indexOpenMain,
  setIndexOpenMain,
  typeListDeliver,
  products
}) => {
  const dispatch = useDispatch();
  const priceDops = useMemo(() => {
    return countDops({
      item,
      city,
      deliveryType,
    });
  }, [item, city, deliveryType]);

  const { isComboBox, comboItems, comboBoxSlots, isRootLevel } = useComboBox(item, root);
  const [firstPizzaHalf, secondPizzaHalf] = useMemo(() => {
    return item?.halves || Array(2).fill(undefined);
  }, [item?.halves]);

  const [isOpenComment, setIsOpenComment] = useState(false);
  const [isOpenMain, setIsOpenMain] = useState(false);

  const fetchPizzaHalves = useCallback(async (search) => {
    const params = {
      search_halves: 1,
      city_sync_id: city,
      delivery_type_id: deliveryType,
      restaurant: restaurant,
      search: search,
    };

    const { data } = await api.products.getList(params);
    const { title } = (firstPizzaHalf || secondPizzaHalf) || {};
    const allowedPizzaSize = title?.match(/\d+/g)?.find(function (item) {
      return item > 20; // if some digit is greater than 20, probably it's pizza size
    });

    const output = data.map(({ title_ua: label, ...value }) => ({
      label,
      value: {
        title: label,
        ...value,
      },
    }));

    return output.filter((item) => {
      return !allowedPizzaSize || item.label?.includes(allowedPizzaSize);
    });

  }, [city, restaurant, deliveryType, firstPizzaHalf, secondPizzaHalf]);

  const choosePizzaHalf = useCallback(({
    value,
    valueKey: { half, index },
  }) => {
    if (value !== null) {
      const { value: product } = value;
      return dispatch(selectPizzaHalf(index, half, product));
    }
    dispatch(selectPizzaHalf(index, half, undefined));
  }, [dispatch]);

  useEffect(() => {
    setIsOpenComment(!!item.comment);

    setIsOpenMain((prev) => {
      let status = false;

      if (index == 0) {
        status = true;
      } else {
        if (indexOpenMain.id == id && indexOpenMain.index == index) {
          status = true;
        }
      }

      return status;
    });
  }, [indexOpenMain, products]);

  const hadlOpenMain = useCallback(() => {
    setIndexOpenMain((prev) => {
      if (prev.id == id && prev.index == index)
        return { index: undefined, id: undefined };

      return { index, id };
    });
  }, [index, id])

  const handleQuantityOrder = (index, e, item) => {
    let v = +e.target.value.replace(/[^\d]/g, '');

    if (v <= 0)
      v = 1;

    if (v >= 50)
      v = 50;

    dispatch(editCuantityOrder(index, v, item))
  }

  const isPizzaHalves = pizzaHalvesArticles.includes(item?.product?.article ?? item?.article);

  return (
    <Item isGift={item?.is_gift} isPizzaHalves={isPizzaHalves} isStrock={item?.stock || false}>
      {
        index != 0 && (
          <RowBtnClos onClick={hadlOpenMain}>
            {
              (indexOpenMain.id == id) && (indexOpenMain.index == index) ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />
            }
          </RowBtnClos>
        )
      }

      <Roew>
        <Title>
          {isSwappableProduct(item, order) ? (
            <Dropdown
              title="Позиція на вибір"
              width="300px"
              onChange={(event) => {
                const id = event.target.value;
                dispatch(selectSwappableProduct(id, item.status));
              }}
              value={item?.id}
              list={(order[`${item.status}s`] || []).map(({ id, product }) => ({
                title: product.title_ua,
                value: id,
              }))}
            />
          ) : item?.product?.title_ua ?? item?.title_ua ?? item?.title}
        </Title>
        {(isPizzaHalves && isOpenMain) && (
          <RowArt>
            <SmCol>
              <Select
                label='Перша половинка'
                placeholder='Оберіть половинку'
                onChange={choosePizzaHalf}
                selectedOption={firstPizzaHalf && { label: firstPizzaHalf.title, value: firstPizzaHalf.article }}
                valueKey={{ index, half: 'first_half' }}
                loadOptions={fetchPizzaHalves}
                isSearchable
                isClearable
                async
              />

              {(firstPizzaHalf || secondPizzaHalf) && (
                <PizzaHalfInfo isVisible={firstPizzaHalf}>
                  <Text><span>Ціна:</span> {firstPizzaHalf?.price ?? '-'} грн.</Text>
                  <Text><span>Арт:</span> {firstPizzaHalf?.article ?? '-'}</Text>
                </PizzaHalfInfo>
              )}

            </SmCol>
            <SmCol>
              <Select
                label='Друга половинка'
                placeholder='Оберіть половинку'
                onChange={choosePizzaHalf}
                selectedOption={secondPizzaHalf && { label: secondPizzaHalf.title, value: secondPizzaHalf.article }}
                valueKey={{ index, half: 'second_half' }}
                loadOptions={fetchPizzaHalves}
                isSearchable
                isClearable
                async
              />

              {(firstPizzaHalf || secondPizzaHalf) && (
                <PizzaHalfInfo isVisible={secondPizzaHalf}>
                  <Text><span>Ціна:</span> {secondPizzaHalf?.price ?? '-'} грн.</Text>
                  <Text><span>Арт:</span> {secondPizzaHalf?.article ?? '-'}</Text>
                </PizzaHalfInfo>
              )}

            </SmCol>
          </RowArt>
        )}
        {(!isOpenMain && isPizzaHalves) && (
          <RowArt>
            <div>
              {firstPizzaHalf && (<Text><span>Зліва: </span> {firstPizzaHalf.title}</Text>)}
              {secondPizzaHalf && (<Text><span>Справа: </span> {secondPizzaHalf.title}</Text>)}
            </div>
          </RowArt>
        )}
        <RowArt>
          <div style={{ display: 'flex', gap: '10px' }}>
            {(!isPizzaHalves || !isOpenMain) && (<Text><span>Ціна:</span> {price} грн.</Text>)}
            {/* ^ Якщо не половинки, то завжди відображаємо ціну продукту. Якщо половинки - тільки у згорнутому стані */}
            {
              !isOpenMain && isRootLevel && (
                <Text><span>Кількість:</span> {item.quantity} шт.</Text>
              )
            }
          </div>
          {!isPizzaHalves && (
            <Text><span>Арт:</span> {item?.product?.article ?? item?.article}</Text>
          )}
        </RowArt>

        {(isComboBox && isOpenMain) && (
          <List>
            <RenderItemList
              products={comboItems}
              order={order}
              city={city}
              restaurant={restaurant}
              deliveryType={deliveryType}
              onOpenDeleteModal={onOpenDeleteModal}
              typeListDeliver={typeListDeliver}
              root={root + `[${index}]` + '.' + 'combo_items'}
            />
            <RenderComboBoxSlots
              slots={comboBoxSlots}
              root={root + `[${index}]` + '.' + 'combo_items'}
            />
          </List>
        )}

        <ButtonHolder>
          <IconButton
            icon={<DeleteIcon />}
            onClick={() => onOpenDeleteModal(root, item)}
          />
        </ButtonHolder>

        {isOpenMain && (
          <MainWrap>
            {(isRootLevel) && ( // На теперішній момент приховуємо кількість та всього для вкладених продуктів
              <Quantity>
                <ButtonBar>
                  <div>Кількість:</div>
                  {
                    item?.is_gift != 1 && (
                      <IconButton
                        icon={<RemoveIcon />}
                        // disabled={(isArray(item?.additions) && item.additions.length > 0)}
                        onClick={() => dispatch(decreaseOrderItem(index, item))}
                      />
                    )
                  }
                  {
                    item.is_gift != 1 && (
                      <div className="int int_quantity">
                        <TextInput
                          width="54px"
                          height="22px"
                          min={1}
                          max={80}
                          value={item.quantity}
                          onChange={(e) => handleQuantityOrder(index, e, item)}
                        />
                      </div>
                    )
                  }
                  <div className={` ${item.is_gift == 1 ? 'ml-1' : ''}`}> {item.is_gift == 1 ? 1 : ' '} шт.</div>
                  {
                    item?.is_gift != 1 && (
                      <IconButton
                        icon={<AddIcon />}
                        // disabled={(isArray(item?.additions) && item.additions.length > 0)}
                        onClick={() => dispatch(increaseOrderItem(index, item))}
                      />
                    )
                  }
                </ButtonBar>
                <div>Всього: {pricePeint + priceDops} грн.</div>
              </Quantity>
            )}

            {isRootLevel && (
              <RenderAdditions
                productId={item.id}
                productIndex={index}
                numberQuantity={item.quantity}
                activeDopsList={item.additions}
              />
            )}

            <RowCom>
              Коментар
              {
                !item.comment && (
                  <div>
                    <button onClick={() => setIsOpenComment(prev => !prev)}>{isOpenComment ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />} </button>
                  </div>
                )

              }
            </RowCom>

            {
              isOpenComment && (
                <TextArea
                  onChange={(e) =>
                    dispatch(updateOrderItemComment(root, index, e.target.value))
                  }
                  value={item.comment}
                />
              )
            }
          </MainWrap>
        )}

        {(!isOpenMain && !!item?.additions?.length) && (
          <ShortDops>
            {item.additions.map(({ quantity, addition_id: id, ...itemAddition }, i) => {
              const price = getAdditionPrice(itemAddition, city, deliveryType);
              return (
                <p key={`short-addition-display-${id}`}>
                  {i + 1}. {itemAddition.addition?.name} x{quantity} ({price * quantity} грн.)
                </p>
              );
            })}
          </ShortDops>
        )}

      </Roew>
    </Item>
  )
}

const RenderItemList = ({
  products,
  order,
  city,
  restaurant,
  deliveryType,
  typeListDeliver,
  onOpenDeleteModal,
  root = 'items',
}) => {
  const [indexOpenMain, setIndexOpenMain] = useState({
    index: undefined,
    id: undefined
  });

  return products.map((item, index) => {
    let price = +item.price;
    let pricePeint = +item.sum;

    return (
      <RenderItem
        key={`${item.id}-${index}`} // index
        index={index}
        id={item.id}
        item={item}
        order={order}
        price={price}
        pricePeint={pricePeint}
        city={city}
        restaurant={restaurant}
        deliveryType={deliveryType}
        onOpenDeleteModal={onOpenDeleteModal}
        indexOpenMain={indexOpenMain}
        setIndexOpenMain={setIndexOpenMain}
        products={products}
        typeListDeliver={typeListDeliver}
        root={root}
      />
    );
  });
};


const ShortDops = styled.div`
  color: white;
  font-size: 12px;
`;

const RowBtnClos = styled.div`
  display: flex;
  justify-content: center;
  padding: 8px 0;
  margin-right: 10px;
  color: ${(p) => p.theme.accentColor};
  border: 1px solid ${(p) => p.theme.accentColor};
  font-size: 14px;
    &:hover {
      border-color: #fff;
    }
`;

const MainWrap = styled.div``;

const RowCom = styled.div`
  margin-left: 4px;
  color: #FFF;
  font-size: 14px;
  display: flex;
  align-items: center;
  & > div {
    margin-left: 10px;
  }
  button {
    cursor: pointer;
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${(p) => p.theme.accentColor};
    svg {
      font-size: 16px;
    }
  }
`;

const Roew = styled.div`
  flex: 1 1 auto;
`;

const SmCol = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const PizzaHalfInfo = styled.div`
  ${(p) => !p.isVisible ? 'visibility: hidden' : ''}
`;

const Quantity = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 0;
  margin: 4px 0;
  border-top: ${(p) => p.theme.inputBorder};
  border-bottom: ${(p) => p.theme.inputBorder};
  color: ${(p) => p.theme.secondaryColor};
`;

const ButtonBar = styled.div`
  display: flex;
  align-items: center;
  .int {
    div {
      margin: 0;
      padding: 0;
    }
    span {
      0
    }
  }
  input {
    margin: 0;
  }
`;

const ButtonHolder = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
`;

const RowArt = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  & > div {
    &:first-child {
      margin-right: 10px;
    }
  }
`;

const Text = styled.div`
  margin-bottom: 5px;
  color: ${(p) => p.theme.secondaryColor};
  & > span {
    color: ${(p) => p.theme.accentColor};
  }
`;

const List = styled.ul``;

export default RenderItemList;
