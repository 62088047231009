import React, { useEffect, useState, useMemo } from "react";
import styled, { useTheme } from "styled-components";

import history from "constants/history";
import api from "apiSingleton";

import { CRM_VERSION } from "config";

import Navigation from "./navigation/Navigation";
import Notifications from "components/notifications";
import MaintenanceAlert from "components/MaintenanceAlert";
import ModalConfirmLogout from "components/modals/ModalConfirmLogout";
import ModalBugReport from "components/modals/ModalBugReport";

import Snowfall from "react-snowfall";

const CommonLayout = ({
  children,
  user,
  tabs,
  openModalConfirmLogout,
  openModalBugReport,
}) => {
  const theme = useTheme();
  const displaySnow = useMemo(() => {
    const isWinter = [11, 0, 1].includes(new Date().getMonth());
    return isWinter && history?.location?.pathname?.includes('/callcenter');
  }, [history?.location?.pathname]); // display snow for callcenter

  const [activeTab, setActiveTab] = useState(null);
  const [maintenance, setMaintenance] = useState({ 
    open: false, 
    showReloadButton: false, 
    message: null 
  });

  useEffect(
    () => setActiveTab(history?.location.pathname),
    [history?.location?.pathname]
  );

  useEffect( // check for crm options
    () => {
      async function optionsCheck() {
        try {
          const { technicalWorks: { message, status: open }, version } = await api.settings.getCRMOptions();
          setMaintenance(() => {
            if (CRM_VERSION !== version) {
              return { 
                open: true, 
                showReloadButton: true,
                message: `Ваша версія (${CRM_VERSION}) застаріла. Наразі доступна актуальна версія ${version}. Рекомендуємо оновитися для забезпечення найкращої роботи та доступу до нових функцій!`,
              };
            } else {
              return { open, message };
            }
          });
        } catch (error) {
          console.error('Помилка отримання опцій CRM', error);
        }
      }

      optionsCheck(); // run immediately
 
      const interval = setInterval(optionsCheck, 60000);
      return () => clearInterval(interval);
    },
    [],
  );

  return (
    <>
      <Navigation
        user={user}
        tabs={tabs}
        activeTab={activeTab}
        logout={openModalConfirmLogout}
        openModalBugReport={openModalBugReport}
      />

      <MaintenanceAlert 
        isOpen={maintenance.open} 
        message={maintenance.message} 
        showReloadButton={maintenance.showReloadButton}
      />

      <Container theme={theme} $maintenance={maintenance.open}>
        {children}
      </Container>

      <Notifications />
  
      <ModalConfirmLogout />
      <ModalBugReport />

      {displaySnow && <Snowfall style={{ zIndex: 999 }} />}
    </>
  );
};

const Container = styled.section`
  padding: ${(p) => 58 + (p.$maintenance ? 35 : 0)}px 14px 14px 14px;
  min-height: calc(100vh - 50px);
`;

export default React.memo(CommonLayout);
