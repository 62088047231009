import { useState, useEffect } from "react";
import { uk } from "date-fns/locale";
import PropTypes from "prop-types";
import styled from "styled-components";
import { DateRange } from "react-date-range";
import { find } from "lodash";

import { TextInput } from "components/ui-kit/inputs/TextInput";
import { Dropdown } from "components/ui-kit/inputs/Dropdown";
import Radio from "components/ui-kit/Radio";
import Select from "components/ui-kit/Select";
import Checkbox from "components/ui-kit/Checkbox";
import styles from "../scss/settings.module.scss";

import { RADIO_SCHEME, RADIO_SCHEME_ACTIONS, RADIO_SCHEME_PERIOD } from "constants/schemes";
import { TextArea } from "components/ui-kit/inputs/TextArea";

import { restaurantsMappedId } from "utils/mappers/restaurants";
import { activeChecked } from "utils/helpers/checkeds";
import { productMappedSelect } from "utils/mappers/products";

import { rangesDate } from "utils/helpers/date";
import { productMappedSelectedItem } from "utils/mappers/products";
import { product_discount } from "constants/promotions";

const days = [
    {
        title: "Пн.",
        value: "Monday",
    },
    {
        title: "Вт.",
        value: "Tuesday",
    },
    {
        title: "Ср.",
        value: "Wednesday",
    },
    {
        title: "Чт.",
        value: "Thursday",
    },
    {
        title: "Пт.",
        value: "Friday",
    },
    {
        title: "Сб.",
        value: "Saturday",
    },
    {
        title: "Нд.",
        value: "Sunday",
    }
];

const discounts = [
    { title: "-", value: null },
    {
        title: "грн.",
        value: "currency",
    },
    {
        title: "%",
        value: "percent",
    }
];

export const PromotionTypeProductDiscount = ({
    //props
    error,
    restaurants,
    products,
    promotionsEdit,
    updateFormPromotion = () => { },
    updateFormPromotionWeek = () => { },
    getProducts = () => { },
}) => {

    const [productsLoading, setProductsLoading] = useState(false);
    useEffect(() => {
        if (promotionsEdit.type === product_discount && !!promotionsEdit.restaurant_id) {
            const restaurant = find(restaurants, { id: +promotionsEdit.restaurant_id });
            if (!restaurant)
                return;

            setProductsLoading(true);
            getProducts({
                short_projection: true,
                restaurant: restaurant.code,
            },
                setProductsLoading,
                () => { },
                true);
        }
    }, [restaurants, promotionsEdit.restaurant_id, promotionsEdit.type]);

    return (
        <div>
            <div className={`${styles.row_form_two}`}>
                <div className={`${styles.wr_drop} ${styles.item_two}`}>

                    <Dropdown
                        title='Бренд'
                        isBlack={true}
                        width="100%"
                        onChange={(e) => updateFormPromotion("restaurant_id", e.target.value)}
                        value={promotionsEdit.restaurant_id}
                        list={restaurantsMappedId(restaurants)}
                        isLight={true}
                        error={error.restaurant_id}
                        fontSize={'14px'}
                    />

                    <Row>

                        <Select
                            isLoad={productsLoading}
                            selectOptions={productMappedSelect(products)}
                            selectedOption={productMappedSelectedItem(products, promotionsEdit.discount_product)}
                            valueKey='discount_product'
                            label='Знижка на товар'
                            mode={'primary'}
                            onChange={(option) => {
                                const { value: id, label } = option.value;
                                updateFormPromotion("discount_product", id);
                                updateFormPromotion("name", `Знижка на товар ${label}`);
                            }}
                            isSearchable
                            noMargin
                        />

                        <TextInput
                            title="Знижка"
                            isStyle={true}
                            onChange={(e) => updateFormPromotion("discount_amount", e.target.value)}
                            value={promotionsEdit.discount_amount}
                            list={discounts}
                            error={error.discount_amount}
                            fontSize={'14px'}
                            width="100%"
                            type="number"
                            styleMarg="0"
                            stylePadTop="0"
                        />

                        <Dropdown
                            title='Тип'
                            isBlack={true}
                            onChange={(e) => updateFormPromotion("discount_type", e.target.value)}
                            value={promotionsEdit.discount_type}
                            list={discounts}
                            isLight={true}
                            error={error.discount_type}
                            fontSize={'14px'}
                            width="35%"
                        />

                    </Row>

                    <TextInput
                        title="Назва"
                        type="text"
                        value={promotionsEdit.name}
                        isStyle={true}
                        error={error.name}
                        onChange={(e) => updateFormPromotion("name", e.target.value)}
                    />

                </div>
                <div className={`${styles.wr_drop} ${styles.item_two}`}>
                    <div className={styles.wr_drop}>
                        <Radio
                            colors={"#000"}
                            items={RADIO_SCHEME_PERIOD}
                            valueKey="time_frame_type"
                            value={promotionsEdit.time_frame_type}
                            onChange={(e) => updateFormPromotion("time_frame_type", e.value.value)}
                        />
                    </div>
                    {
                        (promotionsEdit.time_frame_type === "period") && (
                            <div className={styles.wr_range}>
                                <div className={styles.title_input}>Період з - по</div>
                                <DateRange
                                    onChange={(date) => updateFormPromotion("period", [date.selection])}
                                    ranges={rangesDate(promotionsEdit.period)}
                                    locale={uk}
                                />
                            </div>
                        )
                    }
                    {
                        (promotionsEdit.time_frame_type === "days_of_the_week") && (
                            <div>
                                <div className={styles.title_input}>День тижня</div>
                                <div className={styles.items_chec}>
                                    {
                                        days.map((item, index) => (
                                            <Checkbox
                                                key={index}
                                                isStyle={true}
                                                title={item.title}
                                                value={item.value}
                                                checked={activeChecked(item.value, promotionsEdit.days_of_the_week)}
                                                onChecked={updateFormPromotionWeek}
                                            />
                                        ))
                                    }
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
            <TextArea
                isStyle={true}
                title='Опис акції'
                value={promotionsEdit.description}
                error={error.description}
                onChange={(e) => updateFormPromotion("description", e.target.value)}
            />
            <div className={styles.wr_drop}>
                <Radio
                    colors={"#000"}
                    items={RADIO_SCHEME_ACTIONS}
                    label="Працює з акційними товарами ?"
                    valueKey="status"
                    value={promotionsEdit.isActions}
                    onChange={(e) => updateFormPromotion("isActions", +e.value.value)}
                />
            </div>
            <div className={styles.wr_drop}>
                <Radio
                    colors={"#000"}
                    items={RADIO_SCHEME}
                    label="Активність акції"
                    valueKey="status"
                    value={promotionsEdit.status}
                    onChange={(e) => updateFormPromotion("status", +e.value.value)}
                />
            </div>
        </div>
    )
}

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    padding-top: 4px;
`;

PromotionTypeProductDiscount.propTypes = {
    handleSubmit: PropTypes.func,
    updateFormPromotionWeek: PropTypes.func,
    getProducts: PropTypes.func,
};