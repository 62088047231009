import React, { useEffect, useState } from "react";
import styled, { useTheme } from "styled-components";
import { isArray, isObject, clamp } from "lodash";

import { TextInput } from "components/ui-kit/inputs/TextInput";
import { LoadingModal } from "../../loadingOrder/loading";
import InputPhone from "components/ui-kit/Modals/InputPhone";
import ButtonIcon from "components/ui-kit/ButtonIcon";
import SaveIcon from "@mui/icons-material/Save";
import { Dropdown } from "components/ui-kit/inputs/Dropdown";
import Button from "components/ui-kit/Button";
import { TextArea } from "components/ui-kit/inputs/TextArea";
import Checkbox from "components/ui-kit/Checkbox";
import Radio from "components/ui-kit/Radio";
import { CalendarDate } from "components/ui-kit/Calendar/Calendar";
import Select from "components/ui-kit/Select";

import history from 'constants/history';

import { restaurantsMappedId } from "utils/mappers/restaurants";
import { reasonMapped, searchReasonSelectByArrayId, reasonInObjOutid } from "utils/mappers/reason";
import { maxDateBirthday, dataFormat, dateNormal } from "utils/helpers/date";
import { currentDate } from 'utils/helpers/date';

import { RADIO_FLOOR } from "constants/schemes";

import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import Call from "./Call";

const ListCity = ({ data }) => {
    return (
        <WrRow>
            <TitleIte>Міста клієнта</TitleIte>
            {
                isArray(data) && data.length > 0 ? (
                    <UlCity>
                        {
                            data.map((item, index) => (
                                <ItemCity key={index}>{item.name_ua}</ItemCity>
                            ))
                        }
                    </UlCity>
                ) : (
                    <TextNone>Не визначено</TextNone>
                )
            }
        </WrRow>
    )
}

const Address = ({ data }) => {
    let addressLastOrder = 'Не визначено';

    if (isObject(data)) {
        addressLastOrder = `${data.city_name} ${data.street} ${data.house_number} ${data.apartment ? data.apartment : ''}`;
    }

    return (
        <WrRow>
            <TitleIte>Адрес</TitleIte>
            <ItemText>{addressLastOrder}</ItemText>
        </WrRow>
    )
}

const ModalClients = ({
    //state
    user,
    isOpen,
    loadingsModal,
    extensive,
    calls,
    restaurants,
    bonusClient,
    reasonList,
    telestatSettings,
    //dispatch
    closeModalClients,
    updateClients,
    updateClientServer,
    updateFormBonusClient,
    updateBonusClient,
    updateBonusWriteClient,
    getClientsSea = () => { },
    getClientCallByPhoneNumber = () => { },
    updateFilterCalls = () => { },
}) => {
    const theme = useTheme();
    const [isBonus, setIsBonus] = useState(false);
    const isAdmin = user.role_name == 'system_administrator';

    const handleCloseWindow = async () => {
        await closeModalClients();
    }

    const handleOrdersClick = () => history.push(`/client-orders/${extensive?.id}`);
    const onCheckedMinus = async (stats) => {
        updateFormBonusClient("isMinus", stats);
    }

    const handlerChange = (e) => {
        let value = +e.target.value;

        if ((value > 0) || (value == 0)) {
            updateFormBonusClient("value", value);
        } else if (value < 0) {
            updateFormBonusClient("value", 0);
        }
    }

    const onCheckedWrite = (value, id, restaurantId) => {
        updateBonusWriteClient(value, id, restaurantId);
    }

    const onupdateClientServer = () => {
        updateClientServer(getClientsSea);
    }

    useEffect(() => {
        setIsBonus(false);
    }, [isOpen, extensive]);

    return (
        <>
            {
                isOpen && (
                    <BgModal onClick={handleCloseWindow} />
                )
            }

            <Wrapper
                theme={theme}
                isOpen={isOpen}
            >
                <Header>
                    <div style={{ color: "#fff" }}>Клієнт: {extensive?.name}</div>

                    <CloseButton onClick={handleCloseWindow}>
                        <CloseIcon style={{ fontSize: 36 }} />
                    </CloseButton>
                </Header>

                <Block>
                    {
                        !isBonus ? (
                            <>
                                <TextInput
                                    title="Ім'я"
                                    type="text"
                                    value={extensive?.name ?? ''}
                                    onChange={(e) => updateClients("name", e.target.value)}
                                />
                                <InputPhone
                                    title='Номер телефону'
                                    type='text'
                                    value={extensive?.phone ?? ''}
                                    width={"100%"}
                                    onChange={(e) => updateClients("phone", e.target.value)}
                                />
                                <TextInput
                                    title="Джерело"
                                    type="text"
                                    disabled={true}
                                    value={extensive?.source?.name ?? ''}
                                />
                                <WrRange>
                                    <CalendarDate
                                        title="Дата народження"
                                        valueIn={extensive?.birthday || currentDate()}
                                        date={dateNormal(extensive?.birthday || currentDate())}
                                        maxDate={maxDateBirthday()}
                                        onChangeCal={(date) => updateClients("birthday", dataFormat(date))}
                                    />
                                </WrRange>
                                <Radio
                                    colors={"#fff"}
                                    items={RADIO_FLOOR}
                                    label="Стать"
                                    valueKey="sex"
                                    value={extensive.sex}
                                    onChange={(e) => updateClients("sex", e.value.value)}
                                />
                                <ListCity data={extensive?.cities ?? []} />
                                <Address data={extensive?.address} />
                                <TextArea
                                    title="Коментар"
                                    onChange={(e) => updateClients("comment", e.target.value)}
                                    value={extensive?.comment || ""}
                                />
                                <Wrt>
                                    <Select
                                        selectOptions={reasonMapped(reasonList)}
                                        selectedOption={searchReasonSelectByArrayId(extensive.blocking_reasons, reasonList)}
                                        valueKey='blocking_reasons'
                                        label='Причини чорного списку'
                                        isMulti
                                        onChange={({ value }) => updateClients("blocking_reasons", reasonInObjOutid(value))}
                                    />
                                </Wrt>
                                <Fieldset>
                                    <legend>Персональні знижки у % від чеку</legend>
                                    <Grid>
                                        {restaurants.map(({ id, name, code }) => (
                                            <TextInput
                                                key={`personal-discount-${id}`}
                                                title={name}
                                                type="number"
                                                value={extensive.individual_discounts[code]}
                                                onChange={(event) => {
                                                    const value = clamp(+event.target.value, 0, 100) || null;
                                                    updateClients(`individual_discounts.${code}`, value);
                                                }}
                                                min={1}
                                                max={100}
                                            />
                                        ))}
                                    </Grid>
                                </Fieldset>
                                {
                                    extensive.wallet.balances.map((item, index) => (
                                        <WrRowBonuses key={index}>
                                            <TextInput
                                                key={index}
                                                title={`Бонуси бренду: ${item.restaurant}`}
                                                type="text"
                                                disabled={true}
                                                value={item.value}
                                            />
                                            {
                                                isAdmin && (
                                                    <WrChec>
                                                        <Checkbox
                                                            title="Списувати бонуси"
                                                            onChecked={(value) => onCheckedWrite(value, item.wallet_id, item.restaurant_id)}
                                                            checked={item.writes_off}
                                                            value={!item.writes_off ? 1 : 0}
                                                        />
                                                    </WrChec>
                                                )
                                            }
                                        </WrRowBonuses>
                                    ))
                                }
                                <WrBtn>
                                    <Button
                                        label="Всі замовлення"
                                        onClick={handleOrdersClick}
                                    />
                                </WrBtn>
                                <br />
                            </>
                        ) : (
                            isAdmin && (
                                <>
                                    <HeadRebot onClick={() => setIsBonus(false)}>{`< Назад`}</HeadRebot>
                                    <Dropdown
                                        title="Ресторан"
                                        type="text"
                                        onChange={(e) => updateFormBonusClient("restaurantId", +e.target.value)}
                                        value={bonusClient.restaurantId}
                                        list={restaurantsMappedId(restaurants)}
                                    />
                                    <TextInput
                                        title={`Знижка`}
                                        onChange={(e) => handlerChange(e)}
                                        type="number"
                                        value={bonusClient.value}
                                    />
                                    <WrChec>
                                        <Checkbox
                                            title="Відняти бонуси"
                                            onChecked={onCheckedMinus}
                                            checked={bonusClient.isMinus}
                                            value={!bonusClient.isMinus}
                                        />
                                    </WrChec>
                                    <TextArea
                                        title="Коментар бонуса"
                                        onChange={(e) => updateFormBonusClient("comment", e.target.value)}
                                        value={bonusClient.comment}
                                    />
                                </>
                            )
                        )
                    }
                </Block>

                <Menu>
                    {
                        !isBonus ? (
                            <>
                                <ItemBtn>
                                    <ButtonIcon
                                        onClick={onupdateClientServer}
                                        title={"Зберегти зміни"}
                                        icon={<SaveIcon />}
                                    />
                                </ItemBtn>
                                {
                                    isAdmin && (
                                        <ItemBtn>
                                            <ButtonIcon
                                                onClick={() => setIsBonus(true)}
                                                title='Нарахування бонусів'
                                                icon={<AddIcon />}
                                                colorBg={"blue"}
                                            />
                                        </ItemBtn>
                                    )
                                }
                            </>
                        ) : (
                            <ButtonIcon
                                onClick={updateBonusClient}
                                title={"Зберегти"}
                                icon={<SaveIcon />}
                            />
                        )
                    }

                </Menu>

                {
                    loadingsModal && (
                        <LoadingModal />
                    )
                }

                <Call
                    isOpen={isOpen}
                    calls={calls}
                    telestatSettings={telestatSettings}
                    updateFilterCalls={updateFilterCalls}
                    getClientCallByPhoneNumber={getClientCallByPhoneNumber}
                />
            </Wrapper>
        </>
    )
}


const Wrt = styled.div`
    margin-top: 15px;
`;

const WrRange = styled.div`
    margin-top: 10px;
    margin-bottom: 20px;
`;

const Wrapper = styled.div`
    position: fixed;
    right: ${(p) => (p.isOpen ? "0" : "-1000px")};
    top: 0;
    z-index: 20000;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 460px;
    padding-bottom: 30px;
    background-color: ${(p) => p.theme.background};
    transition: 200ms right;
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px 10px 10px;
    border-bottom: ${(p) => p.theme.border39};
    height: 56px;
`;

const CloseButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${(p) => p.theme.accentColor};
    border-radius: 50%;
    background-color: transparent;

    &:hover {
        cursor: pointer;
        background-color: ${(p) => p.theme.backgroundHover};
    }
`;

const WrRowBonuses = styled.div`
    margin-bottom: 10px;
    padding-bottom: 15px;

    &:not(:last-child) {
        border-bottom: 1px solid rgba(0,0,0,0.2);
    }
`;

const BgModal = styled.div`
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.65);
`;

const Block = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 10px 16px;
    overflow-y: scroll;
    margin-bottom: 40px;
    &::-webkit-scrollbar {
        display: none;
    }
`;

const TitleIte = styled.div`
    position: relative;
    margin-bottom: 5px;
    padding: 0px 3px;
    border-radius: 3px;
    color: ${(p) => p.theme.secondaryColor};
    font-size: 14px;
`;

const WrRow = styled.div`
    margin: 3px;
    padding-top: 4px;
`;

const UlCity = styled.ul`
    display: flex;
    margin: 0 -5px;
    flex-wrap: wrap;
`;

const ItemCity = styled.li`
    color: ${(p) => p.theme.lightAccentColor};
    font-size: 14px;
    padding: 0 5px 5px 5px;
`;

const ItemText = styled.div`
    color: ${(p) => p.theme.lightAccentColor};
    font-size: 14px;
`;

const TextNone = styled.div`
    color: #9D9D9D;
    font-size: 14px;
`;

const Menu = styled.ul`
    position: fixed;
    bottom: 12px;
    display: flex;
`;

const ItemBtn = styled.li`
  margin: 0 20px 0 0;
  &:last-child {
    margin-right: 0;
  }
`;

const HeadRebot = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px 10px 10px;
    border-bottom: ${(p) => p.theme.border39};
    color: ${(p) => p.theme.accentColor};
    margin-bottom: 20px;
    cursor: pointer;
    transition: 0.4s;
    &:hover {
        color: #fff;
    }
`;

const WrBtn = styled.div`
    margin: 20px 0 10px 0;
`;

const WrChec = styled.div`
    margin: 15px 0 15px 0;
`;

const Fieldset = styled.fieldset`
    border: 1px solid ${(p) => p.theme.accentColor};
    border-radius: 8px;

    & legend {
        color: ${(p) => p.theme.accentColor};
    }
`;

const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 10px;
`;

export default React.memo(ModalClients);
