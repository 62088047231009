import { useMemo } from 'react';
import { isArray, isEmpty, map, orderBy, remove } from 'lodash';

export const useComboBox = (item, root) => {
  // Ця функція використовується в табі товарів та допомагає
  // Отримати корисні метаданні з приводу комбо-боксів

  const isRootLevel = useMemo(() => root === 'items', [root]);
  const isComboBox = useMemo(() => isRootLevel && isArray(item.product?.combo_boxes) && !isEmpty(item.product.combo_boxes), [isRootLevel, item.product?.combo_boxes]);

  const comboItems = useMemo(() => item.combo_items || [], [item.combo_items]);
  const comboBoxSlots = useMemo(() => {
    // Якщо не комбо бокс, то слотів немає
    if (!isComboBox) return;

    const boxes = orderBy(item.product.combo_boxes.map((box) => {
      const categories = map(box.product_categories, 'sync_id');
      return {
        id: box.id,
        title: map(box.product_categories, 'name').join(' / '),
        categories,
        categoryCount: categories.length,
      }
    }), 'categoryCount');

    map(item.combo_items, 'product.category_sync_id')
      .forEach(function (item) {
        const box = boxes.find((box) => box.categories.includes(item));
        return remove(boxes, { id: box?.id });
      });


    return boxes;

  }, [isComboBox, item.combo_items, item.product?.combo_boxes]);

  return { isRootLevel, isComboBox, comboItems, comboBoxSlots }
}
