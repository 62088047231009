import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectComboBox } from "store/actions/order";
import { isEqual } from "lodash";

// Styled components
import { Item, Title } from "../styles";

const ComboBoxSlot = ({ slot, root, isActive, dispatch }) => {
  const onClick = useCallback(() => {
    dispatch(selectComboBox(
      slot.id,
      slot.categories,
      root,
    ));
  }, [dispatch, root, slot.categories]);

  return (
    <Item onClick={onClick} isActive={isActive} isComboBoxSlot>
      <Title>Вільний слот: {slot.title}</Title>
    </Item>
  )
}

const RenderComboBoxSlots = ({ root, slots }) => {
  const dispatch = useDispatch();
  const {
    slotId: currentComboBoxSlotId,
    root: currentComboBoxRoot,
  } = useSelector((state) => state.order.selectedComboBox);

  return slots.map((slot) => (
    <ComboBoxSlot
      key={slot.id}
      root={root}
      slot={slot}
      isActive={isEqual(currentComboBoxSlotId, slot.id) && isEqual(currentComboBoxRoot, root)}
      dispatch={dispatch}
    />
  ));
}

export default RenderComboBoxSlots;
