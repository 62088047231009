import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled, { useTheme } from "styled-components";
import _, { isArray } from "lodash";

import { removeOrderItem, updateOrderData } from "store/actions/order";

import { TabProducts } from "../TabProducts";
import Radio from "components/ui-kit/Radio";
import ModalDelete from "components/modals/ModalDelete";
import { Dropdown } from "components/ui-kit/inputs/Dropdown";
import { TextInput } from "components/ui-kit/inputs/TextInput";

import { deliveresMapped } from "utils/mappers/delivery";
import { searchPaymentCodeAndReturn } from "utils/helpers/payment";

import { RADIO_SCHEME_IGNORE_STOCK } from "constants/schemes";
import { removeCutleryCard } from "utils/helpers/cutlery";


//
import RenderItemList from "./components/Items";
import RenderCutlery from "./components/Cutlery";

export const TabOrder = ({
  isEdit,
  calAllSum
}) => {
  const dispatch = useDispatch();
  const theme = useTheme();

  const [isOpenModalDelete, setIsOpenModalDelete] = useState({
    isOpen: false,
    id: undefined,
    root: undefined,
  })

  const order = useSelector((state) => state.order.data);
  const products = useSelector((state) => state.order.data.items);
  const [restaurant, city, deliveryType] = useSelector((state) => [
    state.order.data.restaurant,
    state.order.data.address.city_sync_id,
    +state.order.data.delivery_type_id,
  ]);

  const typeListDeliver = useSelector((store) => store.settings.typesOfDelivery.list);

  const {
    settings: {
      typesOfDelivery
    }
  } = useSelector(state => state);

  useEffect(() => {
    removeCutleryCard(order, dispatch);
  }, [order.items]);

  const onOpenDeleteModal = (root, item) => {
    setIsOpenModalDelete({
      isOpen: true,
      root,
      item
    });
  }

  const clearStateDeleteModal = useCallback(() => {
    setIsOpenModalDelete({
      isOpen: false,
      root: undefined,
      item: undefined,
    });
  }, []);

  const onCloseModal = () => {
    clearStateDeleteModal();
  }

  const onDeleteItem = () => {
    dispatch(removeOrderItem(isOpenModalDelete.root, isOpenModalDelete.item));
    onCloseModal();
  }

  return (
    <Wrapper theme={theme} isEdit={isEdit}>
      <Wr>
        <Radio
          colors={"#ffffff"}
          items={RADIO_SCHEME_IGNORE_STOCK}
          label="Ігнорувати акції ?"
          valueKey="status"
          value={order?.ignore_stock}
          disabled={!isEdit}
          onChange={(e) => {
            dispatch(updateOrderData(
              "ignore_stock",
              e.value.value,
              true
            ));
          }}
        />
        <WrSel>
          <Dropdown
            title="Тип доставки"
            width="185px"
            onChange={(e) => {
              dispatch(updateOrderData("delivery_type_id", e.target.value, true));
              dispatch(updateOrderData("payments", [searchPaymentCodeAndReturn(e.target.value, typesOfDelivery.list)]));
            }}
            value={order.delivery_type_id}
            list={deliveresMapped(typesOfDelivery.list)}
            disabled={!isEdit}
          />

          <TextInput
            width="100px"
            title="Персон"
            type="number"
            disabled={!isEdit}
            onChange={(e) => {
              dispatch(updateOrderData("persons", e.target.value, true));
            }}
            value={order.persons}
          />
        </WrSel>
      </Wr>

      <WrPrices>Загальна сума: <span>{order?.items?.length > 0 ? calAllSum : 0}</span> грн.</WrPrices>
      <List>
        {products.length > 0 ? (
          <RenderItemList
            products={products}
            order={order}
            city={city}
            restaurant={restaurant}
            deliveryType={deliveryType}
            onOpenDeleteModal={onOpenDeleteModal}
            typeListDeliver={typeListDeliver}
          />
        ) : (
          <Subtext>Немає позицій</Subtext>
        )}
      </List>

      {
        isArray(order.cutlery_sets) && (order.cutlery_sets.length > 0) && (
          <RenderCutlery data={order.cutlery_sets} />
        )
      }

      {
        isEdit && (
          <TabProducts />
        )
      }

      <ModalDelete
        title={'Видалення позиції'}
        text={`Ви підтверджуєте видалення ?`}
        isOpen={isOpenModalDelete.isOpen}
        funDelete={onDeleteItem}
        onCloseModal={onCloseModal}
      />
    </Wrapper>
  );
};

const Wr = styled.div`
  padding-bottom: 5px;
  margin-bottom: 5px;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 5px;
  border-bottom: ${(p) => p.theme.inputBorder};
  display: flex;
  align-items: center;
`;

const WrPrices = styled.div`
  color: #fff;
  text-aling: center;
  border-bottom: ${(p) => p.theme.inputBorder};
  padding-bottom: 5px;
  margin-bottom: 5px;
  span {
    color: #EDA240;
  }
`;

const WrSel = styled.div`
  margin-left: 10px;
  display: flex;
  span {
    padding-bottom: 0;
  }
  &>div {
    margin: 0;
    padding-top: 0;
    &:nth-child(2) {
      margin-left: 8px;
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const List = styled.ul``;

const Subtext = styled.div`
  font-size: 12px;
  color: ${(p) => p.theme.lightAccentColor};
  padding-top: 10px;
`;
