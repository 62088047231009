import produce from 'immer';
import { nanoid } from 'nanoid';

import {
    GET_PRODUCTS_SUCCESS,
    UPDATE_PRODUCTS_FETCHING,
    UPDATE_PRODUCTS_SEARCH,
    GET_PRODUCT_BY_ID,
    UPDATE_FORM_PRODUCT,
    UPDATE_FORM_PRODUCT_TYPE_PRICE,
    UPDATE_FORM_PRODUCT_COMBO_SLOTS,
    UPDATE_MODAL_PRODUCT_SPECIFICATION,
} from '../constants/products';
import { LOGOUT } from '../constants/session';

const initialState = {
    list: [],
    meta: {
        current_page: 1,
        from: 1,
        last_page: 1
    },
    isFetching: true,
    searchQuery: '',
    modalProductSpecification: {
        isOpen: false,
        isLoad: false,
        specification: {},
    },
    formProduct: {
        id: null,
        restaurant: "",
        article: "",
        title_ua: "",
        weight: null,
        weight_type: "",
        category_sync_id: "",
        type_sync_id: "",
        description_ua: "",
        image: "",
        prices: [],
        type: {},
        category: {},
        media: [],
    }
};

export default produce((draft, action) => {
    const { payload } = action;

    switch (action.type) {
        case UPDATE_FORM_PRODUCT_TYPE_PRICE: {
            let indexDelivery = draft.formProduct.prices[payload.index].delivery_type_prices.findIndex(el => el.delivery_type_id == payload.id);

            if (indexDelivery == -1) {
                draft.formProduct.prices[payload.index].delivery_type_prices.push({
                    delivery_type_id: payload.id,
                    price: +payload.value,
                });
            } else {
                draft.formProduct.prices[payload.index].delivery_type_prices[indexDelivery].price = +payload.value;
            }

            break;
        }

        case UPDATE_FORM_PRODUCT_COMBO_SLOTS: {
            const { slotId, update } = payload;
            if (slotId) {
                const index = draft.formProduct.combo_boxes.findIndex(slot => slot.id === slotId);
                if (index === -1) break;

                // If slotId is set we're manipulating some item
                if (update) {
                    // If update is set, update the item
                    Object.assign(draft.formProduct.combo_boxes[index], update);
                } else {
                    // Otherwise, remove the item
                    draft.formProduct.combo_boxes.splice(index, 1);
                }

            } else {
                // If we have no slotId let's add new item to the list
                draft.formProduct.combo_boxes = [
                    ...draft.formProduct.combo_boxes,
                    { id: nanoid(), required: false, position: null, product_categories: [] },
                ];
            }

            break;
        }

        case UPDATE_FORM_PRODUCT: {
            draft.formProduct[payload.item] = payload.data;
            break;
        }

        case GET_PRODUCT_BY_ID: {
            draft.formProduct = payload;
            break;
        }

        case GET_PRODUCTS_SUCCESS: {
            if (payload.isNewStatus || payload.isRecord) {
                draft.list = payload.data;
            } else {
                draft.list = [...draft.list, ...payload.data];
            }

            draft.meta = payload.meta
            break;
        }

        case UPDATE_PRODUCTS_FETCHING: {
            draft.isFetching = payload.isFetching;
            break;
        }

        case UPDATE_PRODUCTS_SEARCH: {
            draft.searchQuery = payload.searchQuery;
            break;
        }

        case LOGOUT: {
            draft.list = [];
            draft.isFetching = true;
            break;
        }

        case UPDATE_MODAL_PRODUCT_SPECIFICATION: {
            Object.assign(draft.modalProductSpecification, payload);
            break;
        }        

        default:
            break;
    }
}, initialState);
