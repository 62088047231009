import api from "apiSingleton";

import { useDispatch } from "react-redux";
import { useState, useMemo, useCallback, useEffect } from "react";
import { API_URL } from "config";

import { addNotification } from "store/actions/notifications";

import Echo from "laravel-echo";
import Pusher from "pusher-js";

export const useWebsocket = () => {

  const dispatch = useDispatch();
  const options = useMemo(() => {
    const url = new URL(API_URL);
    return {
      broadcaster: "pusher",
      key: "nexus-crm",
      cluster: "eu",
      forceTLS: true,
      wsHost: url.host,
      wsPath: "/ws",
      authEndpoint: API_URL + "/laravel-websockets/auth",
      auth: {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${api.apiClient.getToken()}`,
          "x-app-id": "nexus-crm",
        }
      },
      encrypted: true,
      disableStats: true,
      enabledTransports: ["ws", "wss"],
    }
  }, []);

  const [pusher] = useState(() => new Pusher(options.key, options));
  const [echo] = useState(() => new Echo({ ...options, client: pusher }));

  const pusherConnectionHandler = useCallback((state) => {
    console.log(`Pusher state changed from ${state.previous} to ${state.current}`);
    if (state.current === "disconnected" || state.current === "unavailable") {
      dispatch(addNotification({ war: ["З'єднання з Websocket було розірвано"] }, "warn"))
    }
  }, [dispatch]);

  useEffect(() => {
    pusher.connection.bind("state_change", pusherConnectionHandler);
    return () => {
      pusher.connection.unbind("state_change", pusherConnectionHandler);
      echo.disconnect();
    }
  }, [pusherConnectionHandler, pusher, echo]);

  return echo;
}
