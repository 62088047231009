import styled from "styled-components";

export const Item = styled.li`
  position: relative;
  margin: 5px 5px 8px 5px;
  padding: 10px;
  background-color:  ${(p) => p.isGift || p.isPizzaHalves ? "#244b4a" : p.isStrock ? "#b64e50" : p.theme.backgroundLight};
  border: ${(p) => p.theme.inputBorder};
  border-radius: 10px;
  display: flex;

  ${(p) => p.isComboBoxSlot ? `
    justify-content: center;
    ${p.isActive ? `border-color: ${p.theme.accentColor} !important;` : ''}
    &:hover {
      cursor: pointer;
      background-color: ${p.theme.backgroundHover};
    }
  ` : ''}
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-bottom: 5px;
  color: ${(p) => p.theme.accentColor};
`;

export const ErMin = styled.div`
  padding: 0 0 6px 0;
  color: red;
  font-size: 12px;
`;

export const Warning = styled.div`
  position: absolute;
  right: 10px;
  bottom: 2px;
  color: #f90716;
`;
