import React from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";

import { updateCutlery, removeCutleryItem } from "store/actions/order";

import { IconButton } from "components/ui-kit/buttons/IconButton";
import Radio from "components/ui-kit/Radio";
import { TextInput } from "components/ui-kit/inputs/TextInput";
import { BLACK_LIST } from "constants/schemes";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import DeleteIcon from "@mui/icons-material/Delete";

const CutleryItem = ({ data, index }) => {
  const dispatch = useDispatch();

  return (
    <Item>
      <Roew>
        <RowArtJus>
          <Title>{data.name}</Title>
          <RowArt>
            {
              data.price && (<Text><span>Ціна:</span> {data.price} грн.</Text>)
            }
            <Text><span>Арт: </span>{data.code}</Text>
          </RowArt>
          <ButtonHolder>
            <IconButton
              icon={<DeleteIcon />}
              onClick={() => dispatch(removeCutleryItem(index))}
            />
          </ButtonHolder>
        </RowArtJus>
        <RowBordTop>
          <Radio
            colors={"#ffffff"}
            items={BLACK_LIST}
            label="Потрібні столові прибори ? "
            valueKey="status"
            value={data.status || 1}
            onChange={(e) => {
              dispatch(updateCutlery(data.cutlery_set_id, "status", e.value.value));
            }}
          />
        </RowBordTop>
        {
          (data.status == 1) && (
            <RowArtJusNe>
              <Quantity>
                <ButtonBar>
                  <div>Кількість:</div>
                  <IconButton
                    icon={<RemoveIcon />}
                    onClick={() => dispatch(updateCutlery(data.cutlery_set_id, "quantity", data.quantity - 1))}
                    disabled={data.quantity <= 1}
                  />
                  <div className="int">
                    <TextInput
                      width="68px"
                      type="number"
                      min={1}
                      max={100}
                      value={data.quantity}
                      onChange={(e) => {
                        dispatch(updateCutlery(data.cutlery_set_id, "quantity", e.target.value));
                      }}
                    />
                  </div>
                  <IconButton
                    icon={<AddIcon />}
                    onClick={() => dispatch(updateCutlery(data.cutlery_set_id, "quantity", data.quantity + 1))}
                    disabled={data.quantity >= 100}
                  />
                </ButtonBar>
                {
                  data.price && (
                    <div>Всього: {data.quantity * data.price} грн.</div>
                  )
                }
              </Quantity>
            </RowArtJusNe>
          )
        }
      </Roew>
    </Item>
  )
}

const Cutlery = ({ data }) => {
  return (
    <WrCutlery>
      <TitleCutlery>Столові прибори</TitleCutlery>
      <ListCutlery>
        {
          data.map((item, index) => (
            <CutleryItem
              index={index}
              key={index}
              data={item}
            />
          ))
        }
      </ListCutlery>
    </WrCutlery>
  )
}

const TitleCutlery = styled.div`
  font-size: 14px;
  color: #fff;
`;

const WrCutlery = styled.div`
  margin-top: 16px;
  border-top: 1px solid #EDA240;
  padding-top: 6px;
`;

const ListCutlery = styled.ul``;

const RowBordTop = styled.div`
  border-top: 1px solid #393C49;
  padding: 8px 0 0 0;
  margin: 4px 0 0 0 ;
`;

const RowArtJusNe = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  & > div {
    flex: 1 1 auto;
  }
`;

const RowArtJus = styled.div`
  width: 100%;
`;

export default Cutlery;
