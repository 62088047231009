import * as React from "react";

// scss
import classes from "./MaintenanceAlert.module.scss";

const MaintenanceAlert = ({ isOpen, message, showReloadButton }) => {

  const hardRefresh = React.useCallback(() => {
    console.debug('Should refresh page');
    window.location = window.location.href + '?' + new URLSearchParams({
      refresh: Date.now()
    }).toString();
  }, []);

  return (
    <React.Fragment>
      {
        (isOpen) && (
          <div className={classes.alert}>
            <marquee>{message}</marquee>
            {(showReloadButton) && (
              <button className={classes.hardRefreshButton} onClick={hardRefresh}>
                Перезавантажити сторінку
              </button>
            )}
          </div>
        )
      }
    </React.Fragment>
  );
}

export default MaintenanceAlert;
