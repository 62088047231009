import styled from "styled-components";
import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isArray } from "lodash";

import {
  updateDopAdd,
  removeDopAll,
  removeDopItem,
  oneAddDop,
  oneSubtractDop,
  getOrderItemAdditions,
  toggleAdditionsMenu,
} from "store/actions/order";

import { IconButton } from "components/ui-kit/buttons/IconButton";
import { getAdditionPrice } from "utils/helpers/price";

import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";

import { LoadingCircl } from "components/ui-kit/LoadingScreen/LoadingCircl";

const RenderAdditions = ({
  productId = 0,
  productIndex = 0,
  activeDopsList = [],
  numberQuantity = 1,
}) => {
  const dispatch = useDispatch();
  const orderState = useSelector(state => state.order.data);
  const { isOpen, isLoad, productIndex: menuProductIndex, groups: additionGroups } = useSelector(state => state.order.additionsMenu);
  const [city, deliveryType] = useSelector((state) => [
    state.order.data.address.city_sync_id,
    +state.order.data.delivery_type_id,
  ]);

  const handlerAddDop = useCallback((type, addition) => {
    dispatch(updateDopAdd(
      productIndex, 
      addition.id, 
      addition.name, 
      addition.prices, 
      type, 
      numberQuantity,
    ));
  }, [dispatch, productIndex, numberQuantity]);

  const handlerRemoveDopItem = useCallback((item) => {
    dispatch(removeDopItem(productIndex, item.addition_id, item.price))
  }, [dispatch, productIndex]);

  const handlerDeleteAllDop = useCallback(() => {
    dispatch(removeDopAll(productIndex));
  }, [dispatch, productIndex]);

  const handlerOpenDops = useCallback(() => {
    dispatch(getOrderItemAdditions(productId, productIndex));
  }, [dispatch, productId, productIndex]);

  const addDop = useCallback((productIndex, indexDop) => {
    dispatch(oneAddDop(productIndex, indexDop, numberQuantity));
  }, [dispatch, productIndex, numberQuantity]);

  const subtractDop = useCallback((productIndex, indexDop) => {
    dispatch(oneSubtractDop(productIndex, indexDop, numberQuantity));
  }, [dispatch, productIndex, numberQuantity]);

  return (
    <QuantityDop>
      {(activeDopsList.length > 0) && (
        <WrDop>
          <TitleDop>Допи: </TitleDop>
        </WrDop>
      )}
      {
        isArray(activeDopsList) && (activeDopsList?.length > 0) && (
          <UlDops>
            <RowDopHead>
              <DopName>Назва</DopName>
              <DopCol>Кіл.</DopCol>
              {
                isArray(activeDopsList) && (activeDopsList.length > 0) && (
                  <DopDelete>
                    <IconButton
                      icon={<DeleteIcon />}
                      onClick={handlerDeleteAllDop}
                    />
                  </DopDelete>
                )
              }
            </RowDopHead>
            {
              isArray(activeDopsList) && (activeDopsList.length > 0) && activeDopsList.map((item, index) => {
                const price = getAdditionPrice(item, city, deliveryType);
                return (
                  <RowDop key={index}>
                    <DopName title={item?.addition?.name}>{`${item?.addition?.name} - Ціна: ${price * item.quantity} грн.`}</DopName>
                    <DopCol>
                      <IconButton
                        icon={<RemoveIcon />}
                        onClick={() => subtractDop(productIndex, index)}
                        disabled={1 >= item.quantity}
                      />
                      {item.quantity}
                      <IconButton
                        icon={<AddIcon />}
                        onClick={() => addDop(productIndex, index)}
                        disabled={orderState?.id ? item?.addition_group?.[0]?.type === "radio" : item?.type === "radio"}
                      />
                    </DopCol>
                    <DopDelete>
                      <IconButton
                        icon={<DeleteIcon />}
                        onClick={() => handlerRemoveDopItem(item)}
                      />
                    </DopDelete>
                  </RowDop>
                )
              })
            }
          </UlDops>
        )
      }

      <WrDopBot>
        <TitleDopBtn>Додати доп: </TitleDopBtn>
        <IconButton
          icon={<AddIcon />}
          onClick={handlerOpenDops}
        />
      </WrDopBot>

      {
        (isOpen && (productIndex === menuProductIndex)) && (
          <>
            <OpasDops onClick={() => dispatch(toggleAdditionsMenu(false))} />
            <Dops>
              <DopsHead>
                <span>Доступні допи: </span>
                <CloseButton onClick={() => dispatch(toggleAdditionsMenu(false))}>
                  <CloseIcon style={{ fontSize: 28 }} />
                </CloseButton>
              </DopsHead>
              <Oldops>
                {isLoad && <LoadingCircl mins />}
                {
                  !isLoad && isArray(additionGroups) && additionGroups.map((group, index) => (
                    <div key={index}>
                      <ItemOld>
                        <span style={{ color: '#EDA240', fontSize: 14, paddingBottom: 10 }}>{group.name}:</span>
                      </ItemOld>
                      {
                        isArray(group.additions) && group.additions.map((addition, index) => {
                          const price = getAdditionPrice(addition, city, deliveryType);
                          return (
                            <ItemOld key={index}>
                              <SpanTitleDop>{`${addition.name} - ціна: ${price} грн.`}</SpanTitleDop>
                              <IconButton
                                icon={<AddIcon />}
                                onClick={() => handlerAddDop(group.type, addition)}
                              />
                            </ItemOld>
                          )
                        })
                      }
                    </div>
                  ))
                }
              </Oldops>
            </Dops>
          </>
        )
      }
    </QuantityDop>
  )
}

const QuantityDop = styled.div`
  color: ${(p) => p.theme.secondaryColor};
  position: relative;
`;

const WrDop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const WrDopBot = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TitleDop = styled.div`
  font-size: 14px;
  color: ${(p) => p.theme.accentColor};
  padding-bottom: 5px;
`;

const UlDops = styled.div`
  margin: 0 -8px 0 -8px;
  padding: 2px 0;
`;

const RowDop = styled.div`
  display: flex;
  align-items: center;
  margin: 0 8px 0 8px;
  border-bottom: ${(p) => p.theme.inputBorder};
`;

const RowDopHead = styled.div`
  display: flex;
  align-items: center;
  border-bottom: ${(p) => p.theme.inputBorder};
  margin: 0 8px 0 8px;
  color: #EDA240;
`;

const DopName = styled.div`
  padding: 0 8px;
  width: calc(65% - 8px);
  border-left: ${(p) => p.theme.inputBorder};
  border-right: ${(p) => p.theme.inputBorder};
`;

const DopCol = styled.div`
  padding: 0 8px;
  width: calc(29% - 16px);
  border-right: ${(p) => p.theme.inputBorder};
  display: flex;
  align-items: center;
`;

const DopDelete = styled.div`
  padding: 0 8px;
  width: calc(6% - 16px);
`;

const TitleDopBtn = styled.div`
  font-size: 12px;
`;

const Dops = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: #2D303E;
  border: 1px solid #393C49;
  border-radius: 10px;
  padding: 10px;
  z-index: 99;
`;

const CloseButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(p) => p.theme.accentColor};
  border-radius: 50%;
  background-color: transparent;

  &:hover {
    cursor: pointer;
    background-color: ${(p) => p.theme.backgroundHover};
  }
`;

const DopsHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: ${(p) => p.theme.inputBorder};
  padding-bottom: 5px;
`;

const OpasDops = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 80;
`;

const Oldops = styled.ul`
  max-height: 200px;
  overflow-y: auto;
  margin-top: 15px;
`;

const ItemOld = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  span {
    flex: 1 1 auto;
    padding-right: 20px;
  }
`;

const SpanTitleDop = styled.span`
    flex: 1 1 auto;
    padding-right: 20px;
    transition: 0.4s;
`;

export default RenderAdditions;
